@tailwind base;
@tailwind components;
@tailwind utilities;

html,body{
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    box-shadow: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  

  @keyframes blinkSign {
    50% {
      color: transparent;
    }
  }
  
  .animate-blinkSign {
    animation: blinkSign 1s infinite;
  }

  @keyframes blinkColor {
    0% { color: red; }
    25% { color: blue; }
    50% { color: green; }
    75% { color: orange; }
    100% { color: red; }
  }
  
  .animate-blink {
    animation: blinkColor 1s infinite;
  }
  
  