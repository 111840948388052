@tailwind base;
@tailwind components;
@tailwind utilities;



  
 

  
  /* .content-wrapper {
    overflow-y: auto;
  } */
  
  .content-inner {
    width: 100%; /* Ensure full width for content */
    max-width: 1200px; /* Limit maximum width for larger screens */
  }
  
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* Position the left arrow */
.slick-prev {
    left: 10px; /* Adjust spacing from the left */
}

/* Position the right arrow */
.slick-next {
    right: 10px; /* Adjust spacing from the right */
}

/* Optional: Add hover effect */
.slick-arrow:hover {
    background: rgba(0, 0, 0, 0.8);
}

/* WebKit Browsers */
::-webkit-scrollbar {
  width: 8px; /* Adjust the width */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Thumb color */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb hover color */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

/* Firefox */
/* For WebKit Browsers (Chrome, Safari) */
.scrollable-element::-webkit-scrollbar {
  width: 1px; /* Ultra-thin scrollbar */
}

.scrollable-element::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 1px;
}

.scrollable-element::-webkit-scrollbar-thumb:hover {
  background: #555; /* Slightly darker on hover */
}

/* For Firefox */
.scrollable-element {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 transparent; /* Thumb color & track color */
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
