.spinner {
    width: 72px;
    height: 72px;
    display: grid;
    border-radius: 50%;
    -webkit-mask: radial-gradient(farthest-side,#0000 40%,#9192a7 41%);
    background: linear-gradient(0deg ,rgba(145,146,167,0.5) 50%,rgba(145,146,167,1) 0) center/5.8px 100%,
         linear-gradient(90deg,rgba(145,146,167,0.25) 50%,rgba(145,146,167,0.75) 0) center/100% 5.8px;
    background-repeat: no-repeat;
    animation: spinner-d3o0rx 1s infinite steps(12);
 }
 
 .spinner::before,
 .spinner::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
 }
 
 .spinner::after {
    opacity: 0.83;
    transform: rotate(60deg);
 }
 
 @keyframes spinner-d3o0rx {
    100% {
       transform: rotate(1turn);
    }
 }